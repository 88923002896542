module.exports = {

  colors: {
    text: '#FFFFFF',
    background: '#282A2C',
    primary: '#7EBF00',
    secondary: '#AAABBC',
    gray: '#4E5256',
    black: '#000000',
  },

  fonts: {
    body: 'Red Hat Display, sans-serif',
    heading: 'Red Hat Display, sans-serif',
    button: 'Red Hat Display, sans-serif',
    italic: 'Red Hat Display, sans-serif',
    subtitle: 'Gruppo, sans-serif',
    splash: 'Gruppo, sans-serif',
    logoRegular: 'Manrope, sans-serif',
    logoBold: 'Manrope, sans-serif',
    mono: 'Major Mono Display, monospace',
  },

  fontWeights: {
    body: 400, // Red Hat Display
    heading: 300, // Red Hat Display
    button: 700, // Red Hat Display
    italic: 300, // Red Hat Display
    subtitle: 400, // Gruppo
    splash: 400, // Gruppo
    logoRegular: 400, //Manrope
    logoBold: 700, // Manrope
    mono: 400, // Major Mono Display
  },

  styles: {

    // GLOBAL // ============================================================================================================================

    root: {
      fontFamily: 'Red Hat Display, sans-serif',
      fontSize: '1rem',
      color: 'text',
      background: 'black',
    },

    // CONTAINERS // ========================================================================================================================

    container: {
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
    },

    containerSplash: {
      position: 'absolute',
      display: 'flex', 
      flexDirection: 'row', 
      height: '100vh',
      width: '100%',
      alignItems: 'center', 
      justifyContent: 'center', 
      background: 'none',
      top: '0',
    },

    containerAfterSplash: {
      position: 'absolute',
      display: 'flex', 
      flexDirection: 'row', 
      height: '100%',
      width: '100%',
      top: '100vh',
      alignItems: 'center', 
      justifyContent: 'center', 
      background: 'none',
      zIndex: '5',
    },

    containerHeader: {
      position: 'sticky',
      top: '0',
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column', 
      zIndex: '50',
    },

    // NAVIGATION // ========================================================================================================================

    nav: {
      position: 'sticky',
      display: 'flex',
      alignItems: 'center', // Ensure items are vertically centered
      padding: 'clamp(1.1rem, 0.1rem + 4vw, 1.5rem)',
      background: 'linear-gradient(0deg, rgba(0,0,0,0), rgba(0,0,0,0.1), rgba(0,0,0,0.35), rgba(0,0,0,0.7))',
    },

    navLink: {
      '@media screen and (min-width: 20rem)' : {
        marginRight: '0.74rem',
        color: 'text',
        textDecoration: 'none',
        fontSize: 'clamp(1.25rem, 0.1rem + 4vw, 2.2rem)',
        fontFamily: 'body',
        fontWeight: '400',
        '&:hover': { color: 'primary',  },
        '&.active-link': { color: 'primary', },
      },
    },

    navLogoThin: {
      '@media screen and (min-width: 20rem)' : {
        flex: '1 1 auto', 
        display: 'flex',
        alignItems: 'center', // Ensure items are vertically centered
        justifyContent: 'flex-end',
        color: 'primary',
        fontFamily: 'logoRegular',
        fontSize: 'clamp(1.25rem, 0.1rem + 4vw, 2.2rem)',
        fontWeight: '400',
      },
    },

    navLogoBold: {
      '@media screen and (min-width: 20rem)' : {
        flex: '0 0 auto',
        display: 'flex',
        alignItems: 'center', // Ensure items are vertically centered
        justifyContent: 'flex-start',
        color: 'text',
        fontFamily: 'logoBold',
        fontSize: 'clamp(1.25rem, 0.1rem + 4vw, 2.2rem)',
        fontWeight: '700',
      },
    },


    // SPLASH // ============================================================================================================================

    splash: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      alignItems: 'center', //Aligns vertically
      textAlign: 'center', // Aligns horizontally
      color: 'text',
      fontFamily: 'splash',
      fontSize: 'clamp(3rem, 0.1rem + 6vw, 4rem)',
      fontWeight: '400',
      fontStyle: 'italic',
      top: 'calc(50vh-50%)',
      height: 'fit-content',
      width: '90%',
      zIndex:10,
    },

    subtitle: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center', //Aligns vertically#
      textAlign: 'center',  // Aligns horizontally
      color: 'text',
      fontFamily: 'splash',
      fontSize: 'clamp(1.5rem, 0.1rem + 3vw, 2rem)',
      fontWeight: '700',
      fontStyle: 'normal',
      top: '0',
      left: '0',
    },

    splashbutton: {
      display: 'flex',
      flexDirection: 'row',
      position: 'absolute',
      top: '90vh',
      left: 'calc(50vw-50%)',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'fit-content',
      color: 'text',
      fontFamily: 'button',
      fontStyle: 'normal',
      background: 'none',
      borderRadius: '50%',
      cursor: 'pointer',
      zIndex:15,
      '&:hover': { color: 'primary', background: 'none', },
    },

    // HEADINGS // ==========================================================================================================================

    h1: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      color: 'text',
      margin: '1rem',
    },
  },
}